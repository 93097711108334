<template>
	<div class="details">
		<div class="contains">
			<div class="con_top">
                <div class="con_top_left">
				    <img style="width:360px;height:360px" src="../../assets/images/tongxin/11.png">
                </div>
				<div class="con_top_right">
					<h2>通信网络单元定级备案</h2>
					<p class="new_tit">新品大促，万人疯抢中，尽快选购吧！</p>
                    <div class="pri_box">
					    <p class="title">产品价格<i>￥</i><span class="price">在线咨询</span></p>
                    </div>
                    <div class="price_box">
                        <div class="box_left">
                            <p>说</p><p>明</p>
                        </div>
                        <div class="box_right">
                            <p>
                                从网络安全专业角度帮助企业开展通信网络单元的定级备案、符合性测评、安全风险评估等工作，协助企业依法依规开展电信业务运营
                            </p>
                        </div>
					</div>
				
					<div class="box_ti">
                        <div class="ti_left">
                            <p>数</p><p>量</p>
                        </div>
						<input class="numbers" value="1" type="text" name="">
					</div>
          				
					<div class="box_bottom">
						<!-- <a style="pointer-events: none;background:gray; " href="">立即购买</a> -->
						<a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">咨询专家</a>
					</div>
				</div>
			</div>
		</div>
		
		<div class="conten_body">  
			<div class="conten_left">
				<ul class="nav_list">
					<li><a class="a_active" href="">产品介绍</a></li>
					<li><a href="">服务流程</a></li>
					<li><a href="">服务优势</a></li>
					<li><a href="">常见问题</a></li>
				</ul>
				
            
         <!-- 为什么选择万方？万方有什么优势？ -->
				<div class="con_list">
					<img class="" src="@/assets/images/tongxin/01.jpg">
					<img class="" src="@/assets/images/tongxin/02.jpg">
					<img class="" src="@/assets/images/tongxin/03.jpg">
					<img class="" src="@/assets/images/tongxin/04.jpg">
					<img class="" src="@/assets/images/tongxin/05.jpg">
					<img class="" src="@/assets/images/tongxin/06.jpg">
					<img class="" src="@/assets/images/tongxin/07.jpg">
					<img class="" src="@/assets/images/tongxin/08.jpg">
					
					
				</div>
				
				
			</div>
			<!-- right -->
			<Hotgoods/>
			

		</div>
	</div>
</template>

<script type="text/javascript">
import Hotgoods from "@/components/hotgoods.vue"
// import Selectshop from "@/components/Selectshop.vue"

export default{
components:{
  Hotgoods
//   Selectshop
},
data(){
	return{
		transmitData:{
			title:"通信网络单元定级备案",
			content:"",
			price:'0',
			url:require('../../assets/images/tongxin/11.png')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;

//**/
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}
.nav_list{
	height:39px; 
   border-bottom: 1px solid #CFCDCD;
}
.nav_list li{
	float:left;
	width:120px;
	text-align:center;
}
.nav_list li a{
	display: inline-block;
	height:39px;
	font-size: 15px;
	line-height: 39px;
	color: #007CC6;
}
.nav_list li  .a_active{
   color:#007CC6;
   border-bottom:1px solid #007CC6;
}
.con_list{
	// padding:0 35px;
	overflow:hidden;
}
.con_list img{
	width:100%;
}



.contains{
    margin-top: 20px;
	padding:20px;
	background: #fff;
}
.con_top{
    display: flex;
	overflow:hidden;
}
.con_top_left{
	width:358px;
    margin-right: 75px;
}

.con_top_right{
	width:790px;
}
.con_top_right h2{
	font-size: 25px;
	color: #000000;
    font-weight: bold;
	line-height: 50px;
}
.new_tit{
	background-color:#00A9F2;
	line-height: 18px;
	font-size:16px;
    padding:10px 10px;
	color:#fff;
}
.pri_box{
	// background:url('../assets/images/b03.png');
	padding-top:10px;
	box-sizing:border-box;
}
.pri_box .title{
    font-size: 18px;
    color: #000000;
}
.pri_box i{
	font-size: 23px;
	font-weight: 400;
	color: #C10909;
	line-height: 47px;
    padding-left: 45px;
    padding-right: 10px;
}
.pri_box  .price{
	font-size: 35px;
	font-weight: bold;
	color: #C10909;
	position: relative;
	top:3px;
}
.price_box{
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
    .box_left{
        width: 105px;
        height: 24px;
        padding-right: 15px;
        display: flex;
        justify-content: space-between;
        p{
            display: inline-block;
            font-size: 18px;
            line-height: 18px;
            color: #000000; 
            width: 64px;

        }
    }
    .box_right{
        p{
            font-size: 14px;
            line-height: 16px;
        }
    }
}
.box_ti{
   margin-top:25px;
   display: flex;
   align-items: flex-start;
}
.box_ti .ti_left{
    display: flex;
    justify-content: space-between;
    width: 70px;
    padding-right: 50px;
}
.ti_left p{
    font-size: 18px;
	color: #000000;
	line-height: 18px;
}
.box_ti .numbers{
    width: 69px;
	height: 25px;
	border: 1px solid #000000;
	font-size: 13px;
	font-weight: 300;
	color: #000000;
	line-height: 30px;
    padding-left: 6px;
}
.box_bottom{
	margin-top:10px;
	
}
.box_bottom a{
	width: 162px;
	height: 47px;
	background: #D97602;
	font-size: 20px;
	font-weight: 300;
	text-align: center;
	color: #FFFFFF;
	display: inline-block;
	line-height: 47px;
	margin-right:9px;
}
.box_bottom a:nth-of-type(2){
    color: #fff;
    background:#FF7F02;
    border:1px solid #FF7F02;
}
.details{
	width:1200px;
	margin:0 auto;
}



}


</style>







